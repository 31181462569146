import React, {
  useState,
  useEffect,
  ChangeEvent,
  KeyboardEvent,
  useRef,
} from 'react';
import styles from './style.module.scss';
import Link from 'next/link';
import { decodeHTMLEntities, parseHtml } from '@/utils/common';
import { useRouter } from 'next/router';

type Option = {
  label: string;
  value: string;
  url?: string;
  text?: string;
};

type AutocompleteProps = {
  label?: string;
  placeholder?: string;
  onChange?: (value: Option | null) => void;
  value?: Option | null;
  disabled?: boolean;
  press?: boolean;
  isMobile?: boolean;
  isClearSearch?: boolean;
  sendSearch: (value: any) => void;
};

const AutocompleteComponent: React.FC<AutocompleteProps> = ({
  press,
  label,
  placeholder = 'Start typing...',
  value = null,
  disabled = false,
  isMobile = false,
  isClearSearch,
  sendSearch,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [filteredOptions, setFilteredOptions] = useState<Option[]>([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [noResults, setNoResults] = useState(false);
  const [showTrending, setShowTrending] = useState(false);
  const [down, setDown] = useState(-1);
  const [recentSearch, setRecentSearch] = useState<any[]>([]);
  // const trending = '?type=search&key=trending';
  const dropdownRef = useRef<HTMLDivElement>(null);

  const router = useRouter();

  useEffect(() => {
    if (value) {
      setInputValue(value.label);
      setDown(-1);
    }
  }, [value]);

  useEffect(() => {
    if (inputValue.length > 2) {
      down < 0 && fetchOptions(inputValue);
    } else {
      isMobile && sendSearch([]);
      setFilteredOptions([]);
      setShowDropdown(false);
    }
  }, [inputValue]);

  useEffect(() => {
    if (localStorage.recentSearch) {
      const items: any[] = JSON.parse(localStorage.recentSearch);
      setRecentSearch(items);
    }
  }, []);

  useEffect(() => {
    press && handleEnter();
  }, [press]);
  useEffect(() => {
    setInputValue('');
  }, [isClearSearch]);

  const similarity = (a: any, b: any) => {
    if (a.includes(b)) return a.length;
    else return 0;
  };

  const fetchOptions = async (query: string) => {
    try {
      const search = encodeURIComponent(
        query.replace(/(^\s+|\s+$)|\s/g, '').toLowerCase()
      );
      const conjuctionUrl = 'type=search&key=' + query.replace(/ /g, '-');
      // let token = getCookie('woodenstreetUserAuthToken');
      const output = await fetch(
        'https://osv1zhu7j1.execute-api.ap-southeast-1.amazonaws.com/development/search?q=' +
          search +
          '&&sort=_score%20desc%2Ctype%20asc&&q.options=%7Bfields%3A%5B%27product_name%27%2C%27keyword%27%5D%7D'
      );
      const data = await output.json();

      const response = data?.hits?.hit;

      const len = response?.length || 0;
      const keyword: string[] = [];
      let n = query;
      let check = 0;
      let key = ''; //,keyOpt = '';
      const searchData: any[] = [];
      check = query.length > n.length ? 0 : check;
      if (len > 0) {
        // let li = '';
        for (let i = 0; i < len; i++) {
          const field = response[i]['fields'];
          const name = field['product_name'];
          // const url =
          //   (field['url'] != '--none--'
          //     ? name && field.price
          //       ? '/product/' + field['url']
          //       : field['url']
          //     : `/search?search=${name}&${conjuctionUrl}`) ;

          let url;

          if (field['url'] !== '--none--') {
            if (name && field.price) {
              url = '/product/' + field['url'];
            } else {
              url = field['url'];
            }
          } else {
            url = `/search?search=${name}&${conjuctionUrl}`;
          }

          const type = field['type'] || '';
          const size = parseInt(field['size']) || 0;
          const options = field['option'];
          const id = response[i]['id'];
          // let indexofpro = id.startsWith('t00');
          const indexofB = id.startsWith('tB0');

          // cat option
          if (type != 2 && field['option'] && options.length > 0 && check < 3) {
            // options = uniqueArr(options);
            for (let j = 0; j < options.length; j++) {
              const opt = field['option'][j].split('_');

              // console.log('opt :>> ', opt, query, keyword);
              // console.log(
              //   'similarity :>> ',
              //   similarity(opt[0].toLowerCase(), query.toLowerCase())
              // );
              // if (j == 0 &&opt[0].toLowerCase().startsWith(query.toLowerCase())) {
              //   $('#search-user').getAttribute('placeholder',opt[0].toLowerCase());}
              // uniqueArr(keyword);
              if (
                similarity(opt[0].toLowerCase(), query.toLowerCase()) >= 2 &&
                !keyword.includes(opt[0].toLowerCase())
              ) {
                if (opt[2]) {
                  key =
                    (opt[2].indexOf('?') == -1 ? opt[2] + '?' : opt[2] + '&') +
                    conjuctionUrl;
                } else if (indexofB) {
                  //brand
                  key = '/brands/' + url;
                } else {
                  key = `/search?search=${opt[0]}&${conjuctionUrl}`;
                }
                const post = {
                  label: opt[0].toLowerCase().replace(n, '<b>' + n + '</b>'),
                  value:
                    options[0]?.length > 0 ? options[0]?.split('_')?.[1] : j,
                  text: opt[0],
                  url: key ?? url,
                  key: opt[1] ?? key, // j + 1,
                };
                searchData.push(post);
                // li +='<li><a title="' +opt[0] +'" href="' +key +'">' +opt[0].toLowerCase().replace(n, '<b>' + n + '</b>') +'</a></li>';
                keyword.push(opt[0].toLowerCase());
                check++;
              }
            }
            if (check > 2) break;
          }

          //product
          if (
            type == 2 &&
            check < 7 &&
            !indexofB &&
            !keyword.includes(name.toLowerCase())
          ) {
            if (size != 0) {
              // $('#search-user1').getAttribute('placeholder', '');
              if (check == 0 && name.toLowerCase() != query.toLowerCase()) {
                const post = {
                  label: query,
                  value:
                    options[0]?.length > 0 ? options[0]?.split('_')?.[1] : 0,
                  text: n,
                  url: `/search?search=${query}&${conjuctionUrl}`,
                  key: 0,
                };
                searchData.push(post);
                // li +='<li><a title="' +query +'" href="search&search=' +query +'?' +conjuctionUrl +'"><b>' +query +'</b></a></li>';
                keyword.push(query.toLowerCase());
              }
              const post = {
                label: name.toLowerCase().replace(n, '<b>' + n + '</b>'),
                value: options[0]?.length > 0 ? options[0]?.split('_')?.[1] : 1,
                text: name,
                url: url,
                key: 1,
              };
              searchData.push(post);
              keyword.push(name.toLowerCase());
              check++;
            }
          }
        }
      } else if (check < 2) {
        const output = await fetch(
          'https://2kdxqq67p6.execute-api.ap-southeast-1.amazonaws.com/development?q=' +
            encodeURIComponent(query) +
            '&suggester=auto&size=20'
        );
        const data = await output.json();
        let response = data?.hits?.hit;
        if (response) {
          response = response.suggest.suggestions;
          const len = response.length;
          n = query;
          if (len > 0) {
            for (let i = 0; i < len; i++) {
              const name = response[i]['suggestion'];
              const id = response[i]['id'];
              const indexof = id.indexOf('t00') == -1;

              // product Name
              if (!keyword.includes(name.toLowerCase()) && !indexof) {
                const url = '/search?search=' + name + '&' + conjuctionUrl;
                const post = {
                  label: name.toLowerCase().replace(n, '<b>' + n + '</b>'),
                  text: name,
                  url: url,
                  key: 1,
                };
                searchData.push(post);
                keyword.push(name.toLowerCase());
                check++;
                if (check > 7) break;
              }
            }
          } else {
          }
        }
      }

      // console.log('searchData :>> ', searchData);

      setShowTrending(false);
      setFilteredOptions(searchData);
      isMobile && sendSearch(searchData);
      setShowDropdown(searchData.length > 0);
      setNoResults(searchData.length === 0);
    } catch (error) {
      console.log('log', error);
      isMobile && sendSearch([]);
      setFilteredOptions([]);
      setShowDropdown(false);
      setNoResults(true);
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDown(-1);
    setInputValue(e.target.value);
  };
  const handleShowTrending = (flag: boolean) => {
    setShowTrending(flag);
  };

  const handleOptionClick = (option: Option) => {
    // console.log('option :>> ', option);
    setDown(-1);
    setSerach(option);
    setShowDropdown(false);
    router.replace(
      `/${decodeHTMLEntities(option?.url?.replace('https://www.woodenstreet.com/', ''))}`
    );
    // router.replace(`/${decodeHTMLEntities(option.url?.split('?')[0] ?? '')}`);
  };
  const setSerach = (option: Option) => {
    if (option?.text && option?.url) {
      if (recentSearch.length > 4) recentSearch.pop();
      const post = { text: option?.text, url: option?.url };
      recentSearch.unshift(post);
      setRecentSearch(recentSearch);
      localStorage.recentSearch = JSON.stringify(recentSearch);
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    const options = filteredOptions;
    if (e.key === 'Escape') {
      setShowDropdown(false);
    } else if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
      if (options.length > 0) {
        const key = e.key === 'ArrowUp' ? -1 : 1;
        const count = down < 0 ? 0 : down + key;
        setDown(options.length > count ? count : 0);
        const text = options?.[count]?.text ?? inputValue;
        setInputValue(text);
      } else handleShowTrending(true);
    } else if (e.key === 'Enter') {
      setInputValue('');
      e.preventDefault();
      handleEnter();
    } else handleShowTrending(true);
  };

  const handleEnter = () => {
    if (inputValue.trim() === '') return;

    const sanitizedInput = inputValue.replace(/\?|&/g, '').replace(/ /g, '-');

    let link = `/${sanitizedInput}?type=search&key=${sanitizedInput}`;
    // let link = `/${sanitizedInput}`;
    if (!noResults) {
      const selectedOption = filteredOptions?.[down] ?? filteredOptions?.[0];
      if (selectedOption) {
        link = selectedOption.url
          ? `/${selectedOption.url.split('?')[0]}`
          : link;
        if (selectedOption?.url?.split('?')[0] === '/search')
          link = `/${selectedOption.url}`;
        setSerach(selectedOption);
      }
    } else if (inputValue.includes('/search')) {
      link = inputValue;
    } else {
      link = `/search?search=${encodeURIComponent(inputValue)}&type=search&key=${sanitizedInput}`;
    }

    setShowDropdown(false);
    setShowTrending(false);
    router.replace(link.replace('https://www.woodenstreet.com/', ''));
  };

  return (
    <div
      className={styles.autocomplete}
      onMouseLeave={() => handleShowTrending(false)}
    >
      {label && <label>{label}</label>}
      <input
        type="text"
        className={styles.input}
        value={inputValue}
        onChange={handleInputChange}
        placeholder={placeholder}
        onKeyDown={handleKeyDown}
        disabled={disabled}
        onClick={() => handleShowTrending(true)}
      />
      <>
        {showDropdown && !isMobile && (
          <div className={styles.dropdown} ref={dropdownRef}>
            {filteredOptions?.map((option: any, key) => (
              <div
                key={option.value}
                className={`${styles.option} ${filteredOptions.length === 1 ? styles.mb10 : ''}`}
                onClick={() => handleOptionClick(option)}
              >
                <span className={down === key ? styles.select : ''}>
                  {parseHtml(decodeHTMLEntities(option.label))}
                </span>
              </div>
            ))}
          </div>
        )}
      </>
      {filteredOptions?.length === 0 && showTrending && !isMobile && (
        <div
          id="searchRes"
          className={styles.TopSearchesBox}
          onClick={() => handleShowTrending(false)}
        >
          <div className={styles.treding}>
            {recentSearch?.length > 0 && (
              <>
                <div className={styles.titlesm} id="recentHtmlHead" style={{}}>
                  Recent View
                </div>
                <ul className={styles.resent}>
                  {recentSearch?.map((search, i) => (
                    <li key={i}>
                      <Link href={search.url}>{search.text}</Link>
                    </li>
                  ))}
                </ul>
              </>
            )}
            <div className={styles.titlesm}>Trending</div>
            <div className={styles.mostSearches}>
              <Link href={'/king-size-beds'}>King Size Beds</Link>
              <Link href={'/coffee-tables'}>Coffee Tables</Link>
              <Link href={'/sofa-cum-beds'}>Sofa Cum Beds</Link>
              <Link href={'/fabric-sofas'}>Fabric Sofas</Link>
              <Link href={'/dining-table-sets'}>Dining Table Sets</Link>
              <Link href={'/wooden-sofa'}>Wooden Sofas</Link>
              <Link href={'/lounge-chairs'}>Lounge Chairs</Link>
              <Link href={'/shoe-racks'}>Shoe Racks</Link>
              <Link href={'/tv-units'}>TV Units</Link>
              <Link href={'/home-decors'}>Home Decor</Link>
              <Link href={'/lamps-and-lighting'}>Lighting</Link>
            </div>
          </div>
          <article className={styles.left}>
            <ul
              className={styles.mostSearches}
              id="searchCat"
              style={{ marginTop: 15, marginBottom: 8 }}
            />
          </article>
          {noResults && inputValue.trim() !== '' && (
            <div className={styles.noResults}>
              Sorry, no results for "{inputValue}"
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AutocompleteComponent;
